import * as React from 'react';
import { Container, Box, Typography } from '@mui/material';

import microclone from "../../assets/microclone.png";
import standardclone from "../../assets/standardclone.png";
import seedling from "../../assets/seedling.png";

import { withTranslation, Trans } from 'react-i18next';

function OurTrees({ t }) {
    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            backgroundColor: 'white',
        }}>
            <Container
                sx={{
                    pt: 10,
                    pb: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <img
                            src={microclone}
                            alt={t("ourtrees.Microclone")}
                            style={{
                                width: '250px',
                                maxWidth: '250px',
                            }}
                        />
                    </Box>
                    <Box sx={{ flex: 8 }}>
                        <Typography color="primary" align="left" variant="h4" marked="center" sx={{ mt: 1, mb: 1, fontSize: 31, fontStyle: "italic" }}>
                            {t("ourtrees.Microclones")}
                        </Typography>
                        <Typography color="primary" align="left" variant="body2" marked="center" sx={{ mt: 1 }}>
                            <Trans i18nKey='ourtrees.MicroclonesText' />
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 'auto' }}></Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ flex: 'auto' }}></Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography color="primary" align="left" variant="h4" marked="center" sx={{ mt: 1, mb: 1, fontSize: 31, fontStyle: "italic" }}>
                            {t("ourtrees.Standard clone")}
                        </Typography>
                        <Typography color="primary" align="left" variant="body2" marked="center" sx={{ mt: 1 }}>
                            <Trans i18nKey='ourtrees.StandardCloneText' />
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <img
                            src={standardclone}
                            alt={t("ourtrees.Standard clone")}
                            style={{
                                width: '100%',
                                maxWidth: '250px',
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <img
                            src={seedling}
                            alt={t("ourtrees.Seedling")}
                            style={{
                                width: '250px',
                                maxWidth: '250px',
                            }}
                        />
                    </Box>
                    <Box sx={{ flex: 2 }}>
                        <Typography color="primary" align="left" variant="h4" marked="center" sx={{ mt: 1, mb: 1, fontSize: 31, fontStyle: "italic" }}>
                            {t("ourtrees.Seedling")}
                        </Typography>
                        <Typography color="primary" align="left" variant="body2" marked="center" sx={{ mt: 1 }}>
                            <Trans i18nKey='ourtrees.SeedlingText' />
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 'auto' }}></Box>
                </Box>
            </Container>
        </Box>
    );
}

export default withTranslation()(OurTrees);