import * as React from 'react';
import { Container, Box, Typography, Card, CardContent, IconButton, Button } from '@mui/material';
import { Email, LinkedIn, Phone } from '@mui/icons-material';

import HubspotForm from "react-hubspot-form";

import officeIcon from "../../assets/officeIcon.png";
import rodrigo_profilepic from "../../assets/man_profilepic.png";
import lizette_profilepic from "../../assets/woman_profilepic.png";
import { withTranslation } from 'react-i18next';

function ContactUs({ t }) {
    const [contactDetails,] = React.useState({
        rodrigo: {
            email: "rodrigo@allesbeste.com",
            phone: "27832974692",
            linkedin: "https://linkedin.com/in/rodrigo-duarte-781319202"
        },
        lizette: {
            email: "lizette@allesbeste.com",
            phone: "27649589282"
        },
        office: {
            email: "kwekery@allesbeste.com",
        }
    });

    const handleGetContactDetails = (entity, method) => {
        const form = document.createElement("a");
        if (method === "email") {
            form.setAttribute("href", "mailto:" + contactDetails[entity][method]);
        } else if (method === "phone") {
            form.setAttribute("href", "tel:" + contactDetails[entity][method]);
        } else {
            form.setAttribute("href", contactDetails[entity][method]);
        }
        form.click();
    }

    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            backgroundColor: "secondary.main",
        }}>
            <Container
                sx={{
                    pt: 10,
                    pb: 8,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography color="white" align="center" variant="h1" marked="center" >
                    {t("contactus.Contact us")}
                </Typography>
                <Box sx={{
                    pt: 1,
                    pb: 2,
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    },
                    justifyContent: 'center',
                }}>
                    <Box sx={{
                        padding: 5,
                        textAlign: 'center'
                    }}>
                        <img
                            src={rodrigo_profilepic}
                            alt="Rodrigo Duarte"
                            style={{
                                width: '100px',
                                maxWidth: '100px',
                            }}
                        />
                        <Typography color="white" align="center" variant="h2" marked="center" >
                            Rodrigo Duarte
                        </Typography>
                        <Typography color="white" align="center" variant="h3" marked="center" >
                            {t("contactus.Nursery manager")}
                        </Typography>
                        <IconButton
                            sx={{ margin: 1 }}
                            variant='contactus'
                            onClick={() => handleGetContactDetails("rodrigo", "email")}
                        >
                            <Email />
                        </IconButton>
                        <IconButton
                            sx={{ margin: 1 }}
                            variant='contactus'
                            onClick={() => handleGetContactDetails("rodrigo", "phone")}
                        >
                            <Phone />
                        </IconButton>
                        <IconButton
                            sx={{ margin: 1 }}
                            variant='contactus'
                            onClick={() => handleGetContactDetails("rodrigo", "linkedin")}
                        >
                            <LinkedIn />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        padding: 5,
                        textAlign: 'center'
                    }}>
                        <img
                            src={lizette_profilepic}
                            alt="Lizette Bieldt"
                            style={{
                                width: '100px',
                                maxWidth: '100px',
                            }}
                        />
                        <Typography color="white" align="center" variant="h2" marked="center">
                            Lizette Bieldt
                        </Typography>
                        <Typography color="white" align="center" variant="h3" marked="center" >
                            {t("contactus.Client liaison")}
                        </Typography>
                        <IconButton
                            sx={{ margin: 1 }}
                            variant='contactus'
                            onClick={() => handleGetContactDetails("lizette", "email")}
                        >
                            <Email />
                        </IconButton>
                        <IconButton
                            sx={{ margin: 1 }}
                            variant='contactus'
                            onClick={() => handleGetContactDetails("lizette", "phone")}
                        >
                            <Phone />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'
                    },
                }}>
                    <Box sx={{
                        padding: 5,
                        textAlign: 'center'
                    }}>
                        <img
                            src={officeIcon}
                            alt="email"
                            style={{
                                width: '100px',
                                maxWidth: '100px',
                            }}
                        />
                        <Button
                            variant='contactus'
                            startIcon={<Email />}
                            sx={{ margin: 1, width: '100%' }}
                            onClick={() => handleGetContactDetails("office", "email")}
                        >
                            {t("contactus.Email office")}
                        </Button>
                    </Box>
                    <Box sx={{
                        padding: 5,
                        display: 'flex',
                        flex: 'auto',
                        flexDirection: 'column',
                    }}>
                        <Card sx={{ padding: 1, maxHeight: 500, overflowY: 'scroll' }}>
                            <CardContent>
                                <Typography color="primary" align="left" variant="h2" marked="center" sx={{ pt: 1, pb: 3 }}>
                                    {t("contactus.Contact form")}
                                </Typography>
                                <HubspotForm
                                    region='eu1'
                                    portalId='143416650'
                                    formId='539eb0a5-0138-4707-b540-9bd588958a78'
                                    loading={<div>Loading...</div>}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default withTranslation()(ContactUs);