import * as React from 'react';
import ProductHeroLayout from './herolayout';
import backgroundImage from "../../assets/kwekery_hero.jpg";

export default function ProductHero() {
    return (
        <ProductHeroLayout
            sxBackground={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#a4b14c',
                backgroundPosition: 'center',
            }}
        >
        </ProductHeroLayout>
    );
}