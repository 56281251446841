import * as React from 'react';
import { HashLink } from "react-router-hash-link";
import { Drawer, AppBar, Container, Box, Toolbar, IconButton, Button, List, ListItem, ToggleButtonGroup, ToggleButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation, withTranslation } from 'react-i18next';

import logo from "../../assets/embleme_Kwekery.png";

const CustomHashRouterLink = React.forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <HashLink {...props} />
    </div>
));

const lngs = {
    en: { nativeName: 'English', shortName: 'En' },
    af: { nativeName: 'Afrikaans', shortName: 'Af' },
    es: { nativeName: 'Español', shortName: 'Es' }
};

function ResponsiveAppBar({ t }) {
    const { i18n } = useTranslation();

    const pages = [
        {
            title: t("nav.About us"),
            href: "/#aboutus",
            component: CustomHashRouterLink
        },
        {
            title: t("nav.Our trees"),
            href: "/#ourtrees",
            component: CustomHashRouterLink
        },
        {
            title: t("nav.Rootstocks"),
            href: "/#rootstocks",
            component: CustomHashRouterLink
        },
        {
            title: t("nav.Cultivars"),
            href: "/#cultivars",
            component: CustomHashRouterLink
        },
        // {
        //     title: "Breeding",
        //     href: "/#breeding",
        //     component: CustomHashRouterLink
        // },
        {
            title: t("nav.FAQs"),
            href: "/#faqs",
            component: CustomHashRouterLink
        },
        {
            title: t("nav.Gallery"),
            href: "/#gallery",
            component: CustomHashRouterLink
        },
        {
            title: t("nav.Contact us"),
            href: "/#contactus",
            component: CustomHashRouterLink
        },
    ];

    const [open, setOpen] = React.useState(false);

    const handleSidebarOpen = () => {
        setOpen(true);
    };

    const handleSidebarClose = () => {
        setOpen(false);
    };

    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }} >
                        <img
                            src={logo}
                            alt={t("Allesbeste Nursery")}
                            height="50"
                        />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={handleSidebarOpen}
                            color="secondary"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="left"
                            onClose={handleSidebarClose}
                            open={open}
                            variant="temporary"
                        >
                            <List>
                                {pages.map((page) => (
                                    <ListItem key={page.title}>
                                        <Button
                                            variant="navigation"
                                            component={page.component}
                                            to={page.href}
                                            disabled={!page.href ? true : false}
                                            {...page.endIcon}
                                        >
                                            {page.title}
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                            <Box sx={{ ml: 1, display: 'flex', justifyContent: 'center' }}>
                                <ToggleButtonGroup
                                    size="small"
                                    color='primary'
                                    value={i18n.resolvedLanguage}
                                    exclusive
                                    onChange={(event, newValue) => i18n.changeLanguage(newValue)}
                                >
                                    {Object.keys(lngs).map((lng) => (
                                        <ToggleButton value={lng}>
                                            {lngs[lng].shortName}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Box>
                        </Drawer>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, mr: 1 }} >
                        <img
                            src={logo}
                            alt={t("Allesbeste Nursery")}
                            height="50"
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, flexDirection: "row-reverse" }}>
                        <Box sx={{ my: 2, ml: 1, display: 'inline-flex' }}>
                            <ToggleButtonGroup
                                size="small"
                                color='primary'
                                value={i18n.resolvedLanguage}
                                exclusive
                                onChange={(event, newValue) => i18n.changeLanguage(newValue)}
                            >
                                {Object.keys(lngs).map((lng) => (
                                    <ToggleButton value={lng}>
                                        {lngs[lng].shortName}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.title}
                                    variant="navigation"
                                    component={page.component}
                                    to={page.href}
                                    disabled={!page.href ? true : false}
                                    target={page.newpage ? "_blank" : null}
                                    sx={{ my: 2, display: 'inline-flex' }}
                                    {...page.endIcon}
                                >
                                    {page.title}
                                </Button>
                            ))}
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default withTranslation()(ResponsiveAppBar);