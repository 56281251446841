import * as React from 'react';
import { Grid, Container, Box, Typography, CardContent, Paper } from '@mui/material';
import backgroundImage from "../../assets/aboutus.jpg";
import stars from "../../assets/stars.png";

import { withTranslation, Trans } from 'react-i18next';

function AboutUs({ t }) {
    return (
        <Box sx={{
            width: '100%',
            display: 'table',
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <Box sx={{
                position: "relative",
                display: "flex",
                backgroundColor: 'rgba(164,177,76,0.5)',
            }}>
                <Container
                    sx={{
                        pt: 10,
                        pb: 10,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ pt: 5, pb: 5 }}
                    >
                        <Grid item xs={12} sm={8} sx={{ pb: 5 }}>
                            <Typography color="white" align="center" variant="h3" marked="center">
                                "{t("aboutus.Intro")}"
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{ pt: 2, pb: 2 }}>
                            <Typography color="white" align="center" variant="h5" marked="center">
                                {t("aboutus.Intro2")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ backgroundColor: "#3a2411" }}>
                                        <CardContent sx={{ textAlign: 'center' }}>
                                            <Typography color="white" align="center" variant="h4" marked="center" sx={{ mt: 2, mb: 3, fontSize: 31, fontStyle: "italic" }}>
                                                {t("aboutus.5-star rating")}
                                            </Typography>
                                            <img
                                                src={stars}
                                                alt={t("aboutus.5-star rating")}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '450px',
                                                }}
                                            />
                                            <Typography color="white" align="center" variant="body2" marked="center" sx={{ mt: 1 }}>
                                                {t("aboutus.RatingText")}
                                            </Typography>
                                        </CardContent>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="white" align="left" variant="h1" marked="center" sx={{ pb: 2 }}>
                                        {t("aboutus.What makes us different?")}
                                    </Typography>
                                    <Typography color="white" align="left" variant="body1" marked="center">
                                        <Trans i18nKey='aboutus.WhatMakesUsDifferentText' />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

export default withTranslation()(AboutUs);