import * as React from 'react';
import { Container, Box, Typography, Grid, Divider } from '@mui/material';

import { withTranslation, Trans } from 'react-i18next';

import Cultivar from '../../components/Cultivar';

import CultivarPlus from "../../assets/cultivarPlus.png";
import Edranol from "../../assets/edranol.png";
import Fuerte from "../../assets/fuerte.png";
import Hass from "../../assets/hass.png";
import LambHass from "../../assets/lambhass.png";
import Maluma from "../../assets/maluma.png";
import Pinkerton from "../../assets/pinkerton.png";
import Reed from "../../assets/reed.png";
import Ryan from "../../assets/ryan.png";
import Zutano from "../../assets/zutano.png";

function Cultivars({ t }) {
    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            backgroundColor: "white",
        }}>
            <Container
                sx={{
                    pt: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ mb: 2 }}>
                    <Typography color="secondary" align="left" variant="h1" marked="center" sx={{ fontSize: 48, fontStyle: "italic" }} >
                        {t("cultivars.Cultivars available")}
                    </Typography>
                    <Typography color="secondary" align="left" variant="body1" marked="center" sx={{ fontStyle: 'italic' }} >
                        <Trans i18nKey="cultivars.CultivarsText" />
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={4}
                >
                    <Cultivar item={{
                        name: "Edranol",
                        image: Edranol,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.Intermediate") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Mid season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Upright") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Medium - Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.B type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Fuerte",
                        image: Fuerte,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.Intermediate") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Early to Mid season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Very spreading") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Medium - Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.B type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Hass",
                        image: Hass,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.Intermediate") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Mid season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Spreading") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Small - Medium") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.A type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Lamb Hass",
                        image: LambHass,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.Intermediate to High") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Late season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Upright") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Medium - Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.A type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Maluma",
                        image: Maluma,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.High") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Early to Mid season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Upright") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Medium - Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.A type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Pinkerton",
                        image: Pinkerton,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.High") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Mid season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Spreading") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Medium - Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.A type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Reed",
                        image: Reed,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.Medium to High") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Mid season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Upright") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.A type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Ryan",
                        image: Ryan,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.Medium to High") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Late season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Spreading") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Medium - Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.B type") },
                        ]
                    }} />
                    <Cultivar item={{
                        name: "Zutano",
                        image: Zutano,
                        char: [
                            { name: t("cultivars.Productivity"), value: t("cultivars.Medium to High") },
                            { name: t("cultivars.Harvest window"), value: t("cultivars.Mid season") },
                            { name: t("cultivars.Tree structure"), value: t("cultivars.Upright") },
                            { name: t("cultivars.Fruit size"), value: t("cultivars.Medium - Large") },
                            { name: t("cultivars.Flower type"), value: t("cultivars.B type") },
                        ]
                    }} />

                    <Grid item xs={12} sm={4} md={3}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center'
                        }}>
                            <img
                                src={CultivarPlus}
                                alt="Luna UCR"
                                style={{
                                    width: '100px',
                                    maxWidth: '100px',
                                }}
                            />
                        </Box>
                        <Typography color="primary" align="center" variant="body2" marked="center" sx={{ mt: 2, mb: 1 }}>
                            {t("cultivars.LunaText")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 10 }} />
            </Container>
        </Box>
    );
}

export default withTranslation()(Cultivars);