import * as React from 'react';
import { Typography, Container, Link, Grid, Box } from '@mui/material';

import AllesbesteLogo from "../../assets/allesbeste_logo.png";
import { withTranslation } from 'react-i18next';

function Copyright({ t }) {
    return (
        <Container sx={{ textAlign: 'center' }}>
            {'© '}
            <Link color="inherit" target="_blank" href="https://www.allesbeste.com/">
                AH Ernst & Seuns (Pty) Ltd.
            </Link>{' '}
            {t("footer.All rights reserved")}
            {' '}
            {new Date().getFullYear()}
        </Container>
    );
}

function AppFooter({ t }) {
    return (
        <Typography
            component="footer"
            sx={{ display: 'flex', bgcolor: 'primary.main', color: 'white' }}
        >
            <Container sx={{ my: 5, display: 'flex' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            mb: 3,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                        }}>
                            <Typography variant="body2" marked="left" gutterBottom color="primary.contrastText" sx={{ mr: 2 }}>
                                {t("footer.Powered by")}
                            </Typography>
                            <img
                                src={AllesbesteLogo}
                                alt="Allesbeste"
                                height="50"
                            />
                        </Box>
                        <Copyright t={t} />
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}

export default withTranslation()(AppFooter);