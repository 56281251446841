import * as React from 'react';
import { Box, Fade, ImageList, ImageListItem, Modal, useMediaQuery } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useTheme } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            backgroundcolor: "red"
        }
    },
    img: {
        outline: "none"
    }
}));

function Gallery(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState("false");

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };

    const handleImage = (value) => {
        setImage(value);
        setOpen(true);
    };

    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            backgroundColor: "white",
        }}>
            <Box
                sx={{
                    pt: 3,
                    pb: 3,
                    pl: 1,
                    pr: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}
            >
                <ImageList variant="masonry" cols={matchDownMd ? 3 : 5} gap={8}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                            <img
                                srcSet={`${item.image}`}
                                src={`${item.thumbnail}`}
                                alt={item.title}
                                onClick={(e) => handleImage(item)}
                                loading="lazy"
                                style={{ cursor: 'pointer' }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <Fade in={open} timeout={500} className={classes.img}>
                        <img
                            src={`${image.image}`}
                            alt={image.title}
                            style={{ maxHeight: "90%", maxWidth: "90%" }}
                        />
                    </Fade>
                </Modal>
            </Box>
        </Box>
    );
}

export default Gallery;

const itemData = [
    {
        thumbnail: './images/Gallery/photo1_sm.jpg',
        image: './images/Gallery/photo1_lg.jpg',
        title: 'Microclone',
    },
    {
        thumbnail: './images/Gallery/photo2_sm.jpg',
        image: './images/Gallery/photo2_lg.jpg',
        title: 'Nurse graft',
    },
    {
        thumbnail: './images/Gallery/photo4_sm.jpg',
        image: './images/Gallery/photo4_lg.jpg',
        title: 'Nurse graft',
    },
    {
        thumbnail: './images/Gallery/photo5_sm.jpg',
        image: './images/Gallery/photo5_lg.jpg',
        title: 'Nurse graft',
    },
    {
        thumbnail: './images/Gallery/photo6_sm.jpg',
        image: './images/Gallery/photo6_lg.jpg',
        title: 'Freshly rooted etiolated shoots',
    },
    {
        thumbnail: './images/Gallery/photo7_sm.jpg',
        image: './images/Gallery/photo7_lg.jpg',
        title: 'Seedlings',
    },
    {
        thumbnail: './images/Gallery/photo8_sm.jpg',
        image: './images/Gallery/photo8_lg.jpg',
        title: 'Seedlings',
    },
    {
        thumbnail: './images/Gallery/photo9_sm.jpg',
        image: './images/Gallery/photo9_lg.jpg',
        title: 'Seedlings',
    },
    {
        thumbnail: './images/Gallery/photo10_sm.jpg',
        image: './images/Gallery/photo10_lg.jpg',
        title: 'Cultivar graft',
    },
    {
        thumbnail: './images/Gallery/photo11_sm.jpg',
        image: './images/Gallery/photo11_lg.jpg',
        title: 'Cultivar graft',
    },
    {
        thumbnail: './images/Gallery/photo12_sm.jpg',
        image: './images/Gallery/photo12_lg.jpg',
        title: 'Big bag area',
    },
    {
        thumbnail: './images/Gallery/photo13_sm.jpg',
        image: './images/Gallery/photo13_lg.jpg',
        title: 'Big bag area',
    },
    {
        thumbnail: './images/Gallery/photo14_sm.jpg',
        image: './images/Gallery/photo14_lg.jpg',
        title: 'Big bag area',
    },
    {
        thumbnail: './images/Gallery/photo15_sm.jpg',
        image: './images/Gallery/photo15_lg.jpg',
        title: 'Big bag filling station',
    },
    {
        thumbnail: './images/Gallery/photo16_sm.jpg',
        image: './images/Gallery/photo16_lg.jpg',
        title: 'Recently planted big bags',
    },
    {
        thumbnail: './images/Gallery/photo17_sm.jpg',
        image: './images/Gallery/photo17_lg.jpg',
        title: 'Process of planting out microclones',
    },
    {
        thumbnail: './images/Gallery/photo18_sm.jpg',
        image: './images/Gallery/photo18_lg.jpg',
        title: 'Big bags',
    },
    {
        thumbnail: './images/Gallery/photo19_sm.jpg',
        image: './images/Gallery/photo19_lg.jpg',
        title: 'Development of clonal roots',
    },
    {
        thumbnail: './images/Gallery/photo20_sm.jpg',
        image: './images/Gallery/photo20_lg.jpg',
        title: 'Development of clonal roots',
    },
    {
        thumbnail: './images/Gallery/photo21_sm.jpg',
        image: './images/Gallery/photo21_lg.jpg',
        title: 'Microclones ready to be severed',
    },
    {
        thumbnail: './images/Gallery/photo22_sm.jpg',
        image: './images/Gallery/photo22_lg.jpg',
        title: 'Big bag area',
    },
    {
        thumbnail: './images/Gallery/photo23_sm.jpg',
        image: './images/Gallery/photo23_lg.jpg',
        title: 'Microclone quality control',
    },
    {
        thumbnail: './images/Gallery/photo24_sm.jpg',
        image: './images/Gallery/photo24_lg.jpg',
        title: 'Microclone inspection',
    },
    {
        thumbnail: './images/Gallery/photo27_sm.jpg',
        image: './images/Gallery/photo27_lg.jpg',
        title: 'Microclone quality assessment',
    },
    {
        thumbnail: './images/Gallery/photo28_sm.jpg',
        image: './images/Gallery/photo28_lg.jpg',
        title: 'Planting out microclones',
    },
    {
        thumbnail: './images/Gallery/photo29_sm.jpg',
        image: './images/Gallery/photo29_lg.jpg',
        title: 'Microclonal root development',
    },
    {
        thumbnail: './images/Gallery/photo32_sm.jpg',
        image: './images/Gallery/photo32_lg.jpg',
        title: 'Quality control',
    },
    {
        thumbnail: './images/Gallery/photo33_sm.jpg',
        image: './images/Gallery/photo33_lg.jpg',
        title: 'Grafted cultivars',
    },
    {
        thumbnail: './images/Gallery/photo34_sm.jpg',
        image: './images/Gallery/photo34_lg.jpg',
        title: 'Packaging for export',
    },
    {
        thumbnail: './images/Gallery/photo35_sm.jpg',
        image: './images/Gallery/photo35_lg.jpg',
        title: 'Box ready to be sealed for exporting',
    },
    {
        thumbnail: './images/Gallery/photo36_sm.jpg',
        image: './images/Gallery/photo36_lg.jpg',
        title: 'Wrapping of the microclones',
    },
    {
        thumbnail: './images/Gallery/photo37_sm.jpg',
        image: './images/Gallery/photo37_lg.jpg',
        title: 'Box ready for exporting',
    },
    {
        thumbnail: './images/Gallery/photo38_sm.jpg',
        image: './images/Gallery/photo38_lg.jpg',
        title: 'Export preparations',
    },
    {
        thumbnail: './images/Gallery/photo39_sm.jpg',
        image: './images/Gallery/photo39_lg.jpg',
        title: 'Packaging for export',
    },
    {
        thumbnail: './images/Gallery/photo40_sm.jpg',
        image: './images/Gallery/photo40_lg.jpg',
        title: 'Export preparations',
    },
    {
        thumbnail: './images/Gallery/photo41_sm.jpg',
        image: './images/Gallery/photo41_lg.jpg',
        title: 'Microclones being packed',
    },
    {
        thumbnail: './images/Gallery/photo42_sm.jpg',
        image: './images/Gallery/photo42_lg.jpg',
        title: 'Boxes ready to be sealed',
    },
];