import { Box, Typography } from '@mui/material';
import * as React from 'react';

export default function ItemList(props) {
    const { items } = props;
    return (
        <Box>
            {items.map(item => (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Box sx={{ flex: 6, ml: 1, mr: 1 }}>
                        <Typography color="primary" align="right" variant="body2" marked="center" sx={{ fontWeight: 'bolder' }}>
                            {item.name}
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 6, ml: 1, mr: 1 }}>
                        <Typography color="primary" align="left" variant="body2" marked="center">
                            {item.value}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
}