import * as React from 'react';
import ProductHero from '../../components/Hero';
import AboutUs from './AboutUs';
import Rootstocks from './Rootstocks';
import OurTrees from './OurTrees';
import ContactUs from './ContactUs';
import Gallery from './Gallery';
import Faqs from './Faqs';
import Cultivars from './Cultivars';

function Home() {
    return (
        <React.Fragment>
            <ProductHero />
            <section id="aboutus">
                <AboutUs />
            </section>
            <section id="ourtrees">
                <OurTrees />
            </section>
            <section id="rootstocks">
                <Rootstocks />
            </section>
            <section id="cultivars">
                <Cultivars />
            </section>
            <section id="faqs">
                <Faqs />
            </section>
            <section id="gallery">
                <Gallery />
            </section>
            <section id="contactus">
                <ContactUs />
            </section>
        </React.Fragment>
    );
}

export default Home;