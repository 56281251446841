import * as React from 'react';
import { Grid, Container, Box, Typography, Paper, TableCell, TableRow, TableBody, TableHead, TableContainer, Table } from '@mui/material';
import backgroundImage from "../../assets/rootstocks.jpg";

import { withTranslation } from 'react-i18next';

function Rootstocks({ t }) {
    return (
        <Box sx={{
            width: '100%',
            display: 'block',
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <Box sx={{
                position: "relative",
                display: "flex",
                backgroundColor: 'rgba(164,177,76,0.5)',
            }}>
                <Container
                    sx={{
                        pt: 10,
                        pb: 10,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ pt: 5, pb: 5 }}
                    >
                        <Grid item xs={12} sm={8} sx={{ pb: 1 }}>
                            <Typography color="white" align="center" variant="h1" marked="center">
                                {t("rootstocks.Clonal Rootstocks")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography color="white" align="center" variant="body1" marked="center">
                                {t("rootstocks.ClonalRootstocksText")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">{t("rootstocks.Phytophthora resistance")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Royalties")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Vigor")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Precociousness")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Salt tolerance")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Productivity")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Bounty
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Yes")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Light")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Dusa
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Yes")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Light")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Duke 7
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.High")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.No")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.High")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Light")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.High")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Leola
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Yes")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Light")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Zerala
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Yes")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Good")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: 5, pt: 5, pb: 5 }}
                    >
                        <Grid item xs={12} sm={8}>
                            <Typography color="white" align="center" variant="h1" marked="center">
                                {t("rootstocks.Seedling Rootstocks")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">{t("rootstocks.Phytophthora resistance")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Vigor")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Precociousness and Productivity")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Edranol
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Low to medium")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Medium to Strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.High")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Osmeria
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Low to medium")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Medium")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Medium to High")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Reed
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Low to medium")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Medium to Strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.High")}</TableCell>
                                </TableRow>
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Zutano
                                    </TableCell>
                                    <TableCell align="center">{t("rootstocks.Low to medium")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Strong")}</TableCell>
                                    <TableCell align="center">{t("rootstocks.Very high")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Box>
        </Box>
    );
}

export default withTranslation()(Rootstocks);