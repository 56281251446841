import * as React from 'react';
import { Container, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import avoman from "../../assets/avoman.png";
import { ExpandMore } from '@mui/icons-material';
import { withTranslation, Trans } from 'react-i18next';

function Faqs({ t }) {
    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            backgroundColor: "white",
        }}>
            <Container
                sx={{
                    pt: 10,
                    pb: 5,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: 'row'
                    }
                }}>
                    <Box sx={{
                        flex: 2,
                        pt: 5,
                        pr: 5
                    }}>
                        <img
                            src={avoman}
                            alt={t("faqs.Frequently asked questions")}
                            style={{
                                width: '250px',
                                maxWidth: '250px',
                            }}
                        />
                    </Box>
                    <Box sx={{
                        pl: 5,
                        flex: 'auto',
                    }}>
                        <Typography color="primary" align="left" variant="h1" marked="center" sx={{ fontSize: 42, fontStyle: "italic" }} >
                            {t("faqs.Frequently asked questions")}
                        </Typography>
                        <Box sx={{ pt: 5 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>
                                        {t("faqs.q1")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Trans i18nKey='faqs.a1' />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>
                                        {t("faqs.q2")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Trans i18nKey='faqs.a2' />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>
                                        {t("faqs.q3")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Trans i18nKey='faqs.a3' />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>
                                        {t("faqs.q4")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Trans i18nKey='faqs.a4' />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>
                                        {t("faqs.q5")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Trans i18nKey='faqs.a5' />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default withTranslation()(Faqs);