import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import { grey } from '@mui/material/colors';
const white = '#FFFFFF';

const rawTheme = createTheme({
    palette: {
        primary: {
            contrastText: white,
            dark: colors.brown[900],
            main: '#3A2411',
            light: '#AAA199'
        },
        secondary: {
            contrastText: white,
            dark: '#819D3C',
            main: '#A4B14C',
            light: '#DEECD1'
        },
        success: {
            contrastText: white,
            dark: colors.green[900],
            main: colors.green[600],
            light: colors.green[400]
        },
        info: {
            contrastText: white,
            dark: colors.blue[900],
            main: colors.blue[600],
            light: colors.blue[400]
        },
        warning: {
            contrastText: white,
            dark: colors.orange[900],
            main: colors.orange[600],
            light: colors.orange[400]
        },
        error: {
            contrastText: white,
            dark: colors.red[900],
            main: colors.red[600],
            light: colors.red[400]
        },
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#3A2411',
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "navigation" },
                    style: {
                        color: "#a4b14c",
                        fontWeight: 'normal',
                        textTransform: 'none',
                        "&:hover": {
                            backgroundColor: "transparent",
                            color: "#3a2411"
                        },
                        "&:active": {
                            color: "#3a2411"
                        }
                    }
                },
                {
                    props: { variant: "contactus" },
                    style: {
                        color: "white",
                        fontWeight: 'normal',
                        textTransform: 'none',
                        "&:hover": {
                            backgroundColor: "transparent",
                            color: "#3a2411"
                        },
                        "&:active": {
                            color: "#3a2411"
                        }
                    }
                },
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff'
                },
            }
        },
        MuiIconButton: {
            variants: [
                {
                    props: { variant: "contactus" },
                    style: {
                        color: "white",
                    }
                }
            ]
        }
    }
});

const fontHeader = {
    color: rawTheme.palette.text.primary,
    fontWeight: rawTheme.typography.fontWeightMedium,
    fontFamily: "'Open Sans', sans-serif",
    // textTransform: 'uppercase',
};

const theme = {
    ...rawTheme,
    palette: {
        ...rawTheme.palette,
        background: {
            ...rawTheme.palette.background,
            default: rawTheme.palette.common.white,
            placeholder: grey[200],
        },
    },
    typography: {
        ...rawTheme.typography,
        fontHeader,
        h1: {
            ...rawTheme.typography.h1,
            ...fontHeader,
            letterSpacing: 0,
            fontSize: 36,
        },
        h2: {
            ...rawTheme.typography.h2,
            ...fontHeader,
            fontSize: 20,
        },
        h3: {
            ...rawTheme.typography.h3,
            ...fontHeader,
            fontSize: 20,
            fontStyle: 'italic'
        },
        h4: {
            ...rawTheme.typography.h4,
            ...fontHeader,
            fontWeight: 600,
            fontSize: 17,
        },
        h5: {
            ...rawTheme.typography.h5,
            fontSize: 18,
            fontWeight: rawTheme.typography.fontWeightLight,
        },
        h6: {
            ...rawTheme.typography.h6,
            ...fontHeader,
            fontSize: 18,
            textTransform: 'uppercase',
        },
        subtitle1: {
            ...rawTheme.typography.subtitle1,
            fontSize: 18,
        },
        body1: {
            ...rawTheme.typography.body2,
            fontWeight: rawTheme.typography.fontWeightRegular,
            fontSize: 16,
        },
        body2: {
            ...rawTheme.typography.body1,
            fontSize: 14,
        },
    },
};

export default theme;