import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import ItemList from '../ItemList';

export default function Cultivar(props) {
    const { item } = props;
    return (
        <Grid item xs={12} sm={4} md={3}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center'
            }}>
                <img
                    src={item.image}
                    alt={item.name}
                    style={{
                        width: '100px',
                        maxWidth: '100px',
                    }}
                />
            </Box>
            <Typography color="secondary" align="center" variant="h6" marked="center" sx={{ mt: 1, mb: 1 }}>
                {item.name}
            </Typography>
            <ItemList items={item.char} />
        </Grid>
    );
}