import { Routes, BrowserRouter, Route } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Home from "./views/Home";
import MainLayout from "./hoc/layout/withnav";
import NotFound from "./views/NotFound";
import { Suspense } from "react";
import { Oval } from 'react-loader-spinner';

function App() {
  return (
    <Suspense
      fallback={
        <Oval
          color={theme.palette.secondary.main}
          secondaryColor={theme.palette.secondary.light}
          wrapperStyle={{ justifyContent: "center" }}
        />
      }
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <MainLayout>
                  <Home />
                </MainLayout>
              }
            />

            <Route
              path="/not-found"
              element={
                <MainLayout>
                  <NotFound />
                </MainLayout>
              }
            />
            <Route
              path="*"
              element={
                <MainLayout>
                  <NotFound />
                </MainLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
